<template>
  <mdb-container>
		<mdb-row>
      <mdb-card-group deck>
          <mdb-card v-for= "(obj, index)  in getProdList()" :key="obj.id" :id="'galleryCard'+ index" 
                class="mb-8" style="max-width: 540px">
    <mdb-row class="g-0 xxxmb-8">
      <mdb-col md="5">
              <a :href="'/info/'+obj.id">
                <mdb-card-image 
                  :src="'/img/'+obj.image"
                  :alt="obj.title">
                </mdb-card-image>
              </a>
      </mdb-col>
      <mdb-col md="7">
        <mdb-card-body class="align-items-end">
        
              <mdb-card-title><a :href="'/info/'+obj.id">{{obj.title}}</a></mdb-card-title>
              <mdb-card-text>{{obj.ingr}}</mdb-card-text>
       <!--       <mdb-card-text><h5>מחיר: {{obj.price}}</h5></mdb-card-text> -->
            </mdb-card-body>
            
      </mdb-col>
    </mdb-row>
          </mdb-card>
      </mdb-card-group>
    </mdb-row>
  </mdb-container>
</template>

<script>
import { mdbContainer, mdbRow, mdbCol, mdbCard, mdbCardGroup, mdbCardImage, mdbCardBody, mdbCardTitle, mdbCardText } from 'mdbvue';
export default {
  name: 'List',
  components: {
    mdbContainer, 
    mdbRow,
    mdbCol,
    mdbCard,
    mdbCardGroup,
    mdbCardImage,
    mdbCardBody,
    mdbCardTitle,
    mdbCardText
  },
  methods: {
      getProdList() {
        //if (this.$root.list[0].id<this.$root.list[1].id)
        //  this.$root.list=this.$root.list.sort((a, b) => b.id - a.id); // sort list desc
        //return this.$root.list;
        var list = this.$root.list;//.filter(x => !(x.id==133 || x.id==1230 || x.id==1010));
        var a = [];
        var n = 0;
        for (var i=n;i<120+n && i<list.length;i++)
          a.push(list[i]);
        return a;
      }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.h4 {
  direction: rtl;
  text-align: center;
}
@media (min-width: 576px) {
  .card {
    max-width: 45%;
    min-width: 47%;
  }
  .card-deck {
    display: flex;
    flex-flow: row wrap;
  }
  .xxxmb-8, p {
    break-inside: avoid;
  }
  .yyyy {
    page-break-after: always!important;
  }
}
</style>
<style>
@media print {
  .menu-list {
    display: none!important;
  }
  .card-deck {
    margin-right: -5px!important;
    margin-left: -5px!important;
  }
  .xxxmb-8, p {
    break-inside: avoid;
  }
  .yyyy {
    page-break-after: always!important;
  }
}
</style>
  
