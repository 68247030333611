<template>   
  <mdb-container fluid class="related-text">
    <mdb-row class="pb-2 font-weight-bold">
      <mdb-col col="8" sm="4" class="text-left">
        <img alt="Carmit logo" :src="'/img/'+getObj().image" width="100%" />
      </mdb-col>
      <mdb-col col="12" sm="8" class="text-right">
        <br><br>
        <div id="nav">
          <h1>{{getObj().title}}</h1><br>
          <h4 v-if="getObj().desc">
            {{getObj().desc}}</h4>
          <h4>{{getObj().ingr}}</h4>
          <h5>מחיר: {{obj.price}} <a style="padding: 20px;" href="/contact">להזמנה</a></h5>
        </div>
      </mdb-col>  
    </mdb-row>
    <h5 v-for="(obj) in getInfoList()" :key="obj.id">
      {{obj}}
    </h5>
    <div class="text-right">
    <mdb-tooltip trigger="hover" :options="{placement: 'top'}">
      <span slot="tip"> מיוצר בישראל </span>
      <img slot="reference" width="60px" src="../assets/icon-made-in-israel.png" />
    </mdb-tooltip>
    <mdb-tooltip trigger="hover" :options="{placement: 'top'}">
      <span slot="tip"> ידידותי לסביבה </span>
      <img slot="reference" width="60px" src="../assets/icon-eco.png" />
    </mdb-tooltip>
    <mdb-tooltip trigger="hover" :options="{placement: 'top'}">
      <span slot="tip"> עבודת יד </span>
      <img slot="reference" width="60px" src="../assets/icon-handmade.png" />
    </mdb-tooltip>
    <mdb-tooltip trigger="hover" :options="{placement: 'top'}">
      <span slot="tip"> טבעוני </span>
      <img slot="reference" width="60px" src="../assets/icon-vegan.png" />
    </mdb-tooltip>
    <mdb-tooltip trigger="hover" :options="{placement: 'top'}">
      <span slot="tip"> לא נוסה על בע"ח </span>
      <img slot="reference" width="60px" src="../assets/icon-animal.png" />
    </mdb-tooltip>
    </div>
    <br/>
  </mdb-container>
</template>


<script>
import { mdbContainer, mdbRow, mdbCol, mdbTooltip } from 'mdbvue';
export default {
  name: 'Info',
  components: {
    mdbContainer, 
    mdbRow,
    mdbCol,
    mdbTooltip
  },
  props: {
    id: {
      type: String
    }
  },
  data(){
      return {
          obj: false
      }
  },
  methods: {
      getObj() {
        if (!this.obj && this.id)
        {
          var id = parseInt(this.id);
          this.obj = this.$root.list.find(obj => obj.id == id);
        }
        return this.obj;
      },
      getInfoList() {
        return this.$root.info;
      }
  }
}
</script>

<style scoped>

h4 {
  direction: rtl;
  text-align: right;
  padding: 20px;
}
h5 {
  direction: rtl;
  text-align: right;
}
</style>
