import Vue from 'vue'
import VueRouter from 'vue-router'
import Soaps from '../views/Soaps.vue'
import List from '../views/List.vue'
import Info from '../views/Info.vue'
import Deals from '../views/Deals.vue'
import Gifts from '../views/Gifts.vue'
import About from '../views/About.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Soaps',
    component: Soaps
  },
  {
    path: '/list',
    name: 'List',
    component: List
  },
  {
    path: '/info/:id',
    name: 'info',
    component: Info,
    props: true
  },
  {
    path: '/deals',
    name: 'Deals',
    component: Deals
  },
  {
    path: '/gifts',
    name: 'Gifts',
    component: Gifts
  },
  {
    path: '/contact',
    name: 'About',
    component: About
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    //component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
