<template>
  <mdb-container fluid  class="menu-list">
    <mdb-row class="pb-2 font-weight-bold">
      <mdb-col md="2" class="text-left"><img alt="Carmit logo" src="../assets/logo.ovel.png" /></mdb-col>  
      <mdb-col class="text-right align-self-center">
        <h1>כרמית סבון מהטבע</h1><br>
        <div class="menu-list" style="direction:rtl">
          <mdb-btn outline="primary" darkWaves rounded @click="$router.push('/')">סבונים מוצקים</mdb-btn>
          <mdb-btn outline="primary" darkWaves rounded @click="$router.push('/gifts')">מארזי שי</mdb-btn>
          <mdb-btn outline="primary" darkWaves rounded @click="$router.push('/deals')">מבצעים</mdb-btn>
          <mdb-btn outline="primary" darkWaves rounded @click="$router.push('/contact')">צור קשר</mdb-btn>
        </div>
      </mdb-col>  
    </mdb-row>
  </mdb-container>
</template>

<script>
import { mdbContainer, mdbRow, mdbCol, mdbBtn } from 'mdbvue';
export default {
  name: 'Menu',
  components: {
    mdbContainer, 
    mdbRow,
    mdbCol,
    mdbBtn
  }  
}
</script>

