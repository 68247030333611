<template>   
  <div><br>
    <H4>ניתן לרכוש מארזי שי בהתאמה אישית</H4>
    <H4><li>מארז של 3 סבונים ב 75 &#x20AA;</li></H4>
    <H4><li>מארז של 4 סבונים ב 100 &#x20AA;</li></H4>
    <H4><li>מארז של 5 סבונים ב 125 &#x20AA;</li></H4>
    <H4><li>מארז של 6 סבונים ב 150 &#x20AA;</li></H4>
    <br>
    <H4>מארזים לדוגמה:</H4>
    <mdb-container>
      <mdb-row>
        <mdb-card-group deck>
          <mdb-card>
            <mdb-view hover>
              <mdb-card-image 
                src='/img/box_20210505_195626.png' />
              <mdb-mask flex-center waves overlay="white-slight"></mdb-mask>
            </mdb-view>
          </mdb-card>
          <mdb-card>
            <mdb-view hover>
              <mdb-card-image 
                src='/img/box_20210505_195822.png' />
              <mdb-mask flex-center waves overlay="white-slight"></mdb-mask>
            </mdb-view>
          </mdb-card>
          <mdb-card>
            <mdb-view hover>
              <mdb-card-image 
                src='/img/box_20210612_214344.png' />
              <mdb-mask flex-center waves overlay="white-slight"></mdb-mask>
            </mdb-view>
          </mdb-card>
          <mdb-card>
            <mdb-view hover>
              <mdb-card-image 
                src='/img/box_20210505_200051.png' />
              <mdb-mask flex-center waves overlay="white-slight"></mdb-mask>
            </mdb-view>
          </mdb-card>
        </mdb-card-group>
      </mdb-row>
    </mdb-container>
  </div>
</template>

<script>
import { mdbContainer, mdbRow, mdbCard, mdbCardGroup, mdbCardImage, mdbView, mdbMask } from 'mdbvue';
export default {
  name: 'Gifts',
  components: {
    mdbContainer, 
    mdbRow,
    mdbCard,
    mdbCardGroup,
    mdbCardImage,
    mdbView,
    mdbMask
  }
}
</script>

<style scoped>

h4 {
  direction: rtl;
  text-align: right;
  padding: 0px;
  margin-right: 20px;
}
h5 {
  direction: rtl;
  text-align: right;
}
.card {
  max-width: 80%;
  min-width: 45%;
}
.card-deck {
  margin-left: 20px;
}

</style>

