<template>
  <mdb-container>
		<mdb-row>
      <mdb-card-group deck>
          <mdb-card v-for= "(obj, index)  in getProdList()" :key="obj.id" :id="'galleryCard'+ index">
            <mdb-view hover>
              <a :href="'/info/'+obj.id">
                <mdb-card-image 
                  :src="'/img/'+obj.image"
                  :alt="obj.title"/>
                <mdb-mask flex-center waves overlay="white-slight"></mdb-mask>
              </a>
            </mdb-view>
            <mdb-card-body class="align-items-end">
              <mdb-card-title><a :href="'/info/'+obj.id">{{obj.title}}</a></mdb-card-title>
              <mdb-card-text>{{obj.desc}}</mdb-card-text>
              <mdb-card-text><h5>מחיר: {{obj.price}}</h5></mdb-card-text>
            </mdb-card-body>
          </mdb-card>
      </mdb-card-group>
    </mdb-row>
  </mdb-container>
</template>

<script>
import { mdbContainer, mdbRow, mdbCard, mdbCardGroup, mdbCardImage, mdbCardBody, mdbCardTitle, mdbCardText, mdbView, mdbMask } from 'mdbvue';
export default {
  name: 'Soaps',
  components: {
    mdbContainer, 
    mdbRow,
    mdbCard,
    mdbCardGroup,
    mdbCardImage,
    mdbCardBody,
    mdbCardTitle,
    mdbCardText,
    mdbView,
    mdbMask
  },
  methods: {
      getProdList() {
        //if (this.$root.list[0].id<this.$root.list[1].id)
        //  this.$root.list=this.$root.list.sort((a, b) => b.id - a.id); // sort list desc
        return this.$root.list;
      }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.h4 {
  direction: rtl;
  text-align: center;
}
@media (min-width: 100px) {
.card {
  max-width: 80%;
  min-width: 22%;
  }
.card-deck {
  margin-left: 20px;
  }
}
@media (min-width: 576px) {
.card {
  max-width: 21%;
  min-width: 21%;
  }
.card-deck {
  display: flex;
  flex-flow: row wrap;
  margin-right: -15px;
  margin-left: -15px;
  }
}
</style>


  
