import Vue from 'vue'
import 'bootstrap-css-only/css/bootstrap.min.css'
/* import 'mdbvue/build/css/mdb.css' */
import 'mdbvue/lib/css/mdb.min.css'
import App from './App.vue'
import router from './router'
import data from "@/assets/data.json";

Vue.config.productionTip = false
data.list=data.list.filter(x => !x.skip).sort((a, b) => b.id - a.id); // sort list desc
new Vue({
  data, 
  router,
  render: h => h(App)
}).$mount('#app')
